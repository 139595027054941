import React from 'react';

import styled from 'styled-components';

import { BasicText } from './BasicText';
import { EnquiryInfo } from '../config/enquiryInfo';

const Container = styled.div`
	border: 1px solid black;
	border-radius: 16px;
	padding: 16px;
	margin-bottom: 16px;
`;

const Heading = styled.h2`
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const Title = styled.h3`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const Text = styled(BasicText)`
	font-size: 14px;
`;

interface EnquiryInfoBlockProps {
	enquiryInfoStep: EnquiryInfo;
}

export const EnquiryInfoBlock = ({ enquiryInfoStep }: EnquiryInfoBlockProps) => {
	const renderStepInfo = () =>
		enquiryInfoStep && (
			<>
				<Heading>{enquiryInfoStep.header}</Heading>
				{enquiryInfoStep.content.map((step: any) => {
					return (
						<>
							<Title>{step.title}</Title>
							<Text>{step.reason}</Text>
						</>
					);
				})}
			</>
		);

	return <Container>{renderStepInfo()}</Container>;
};
