import React from 'react';

import styled from 'styled-components';

// TODO: Replace below Input with lib Input
import Input from 'components/user-profile/shared-components/Input';
import FormElementWrapper from 'components/user-profile/shared-components/FormElementWrapper';
import { CountryCodeSelect } from 'lib/CountryCodeSelect';
import { InfoText } from '../../styled-subcomponents/InfoText';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import MobileNumberInput from 'lib/MobileNumberInput';

const StepOne = ({ number, region, onNumberChange, onRegionChange }) => {
	const handleCountryChange = country => {
		const { countryCode } = country;
		onRegionChange(countryCode);
	};

	return (
		<StyledCellphoneStepOneSection>
			<StyledForm>
				<InfoText>Please enter your cellphone number below</InfoText>
				{featureFlagContainer.isEnabled(FeatureFlag.SingleMobileNumberInput) ? (
					<MobileNumberInput
						country={region}
						mobileNumber={number}
						onCountrySelect={onRegionChange}
						onMobileNumberChange={onNumberChange}
					/>
				) : (
					<>
						<CountryCodeSelect value={region} onChange={handleCountryChange} />
						<FormElementWrapper>
							<Input
								type="text"
								name="cell_number"
								value={number}
								onChange={event => {
									onNumberChange(event.target.value);
								}}
								placeholder="Cellphone number (WhatsApp preferred)"
							/>
						</FormElementWrapper>
					</>
				)}
			</StyledForm>
		</StyledCellphoneStepOneSection>
	);
};

export default StepOne;

const StyledCellphoneStepOneSection = styled.div`
	height: 100%;
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	width: 100%;
`;
