import React, { useState } from 'react';

import styled from 'styled-components';

import { SubTitle } from '../../styled-subcomponents/SubTitle';
import { Select, SelectOption } from 'lib/Select';
import { Institution, Campus } from 'models/listing';

const Section = styled.div`
	margin-top: 16px;
`;

interface EducationInfoProps {
	institution?: Institution;
	campus?: Campus;
	showValidationErrors: boolean;
	institutionOptions: SelectOption[];
	campusOptions: SelectOption[];
	onCampusSet(value: boolean): void;
	onDropDownClick(): void;
	onInstitutionSearch(value?: string): void;
	onNoInstitutionFoundClick(): void;
	onFieldChange(fieldChange: { [fieldName: string]: any }): void;
	onCampusFieldChange(fieldChange: { [fieldName: string]: any }): void;
	onInstitutionFieldChange(
		fieldChange: { [fieldName: string]: any },
		newInstitution?: boolean,
	): void;
}

export const EducationInfo = ({
	institutionOptions,
	institution,
	campusOptions,
	campus,
	showValidationErrors,
	onCampusSet,
	onFieldChange,
	onDropDownClick,
	onInstitutionSearch,
	onInstitutionFieldChange,
	onCampusFieldChange,
	onNoInstitutionFoundClick,
}: EducationInfoProps) => {
	const selectedInstitution =
		institution && institutionOptions.find(({ value }) => +value === institution.id);
	let selectedCampus = campus && campusOptions.find(({ label }) => label === campus.name);
	const [campusSet, setCampusSet] = useState<boolean>(!!institution && !!campus);

	const handleCampusSelect = (option: any) => {
		onCampusFieldChange({
			campus: {
				id: +option.value,
				name: option.label,
			},
		});
		setCampusSet(true);
		onCampusSet(true);
	};

	const handleInstitutionSelect = (option: any) => {
		onInstitutionFieldChange({
			institution: {
				id: +option.value,
				name: option.label,
			},
			campus: null,
		});
		setCampusSet(false);
		onCampusSet(false);
	};

	return (
		<Section>
			<SubTitle>Education</SubTitle>
			<Select
				placeholder={'Start typing to search for your institution...'}
				options={institutionOptions}
				selectedOption={selectedInstitution}
				isErrorState={showValidationErrors && !institution}
				onChange={handleInstitutionSelect}
				onDropDownClick={onDropDownClick}
				onNoInstitutionFoundClick={onNoInstitutionFoundClick}
				onInputChange={onInstitutionSearch}
				hasSearchIcon
			/>
			<Select
				placeholder={campusSet ? "I'm not sure yet" : 'Select a campus'}
				options={campusOptions}
				selectedOption={selectedCampus}
				isErrorState={showValidationErrors && (!campusSet || !campus)}
				onChange={handleCampusSelect}
				isStyledPlaceholder={!!campus || campusSet}
			/>
		</Section>
	);
};
