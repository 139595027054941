import styled from 'styled-components';

interface StepTitleProps {
	noMargin?: boolean;
}

export const StepTitle = styled.h1<StepTitleProps>`
	font-weight: bold;
	font-size: 32px;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '8px')};
`;
