import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import api from 'api';
import { History } from 'history';

import { Title } from 'lib/Title';
import { BasicText } from '../styled-subcomponents/BasicText';
import { Listing } from 'models/listing';
import { Button } from 'lib/Button';
import { InfoBlock } from '../styled-subcomponents/InfoBlock';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

import SimilarListingsAccordion from '../../digs-listing/similar-listings-accordion';
import SafetyTips from 'components/digs-listing/SafetyTips';

interface SecondaryHeadingProps {
	noMargin?: boolean;
}

interface BasicTextWithTopMarginProps {
	bottom?: boolean;
}

const BasicTextWithTopMargin = styled(BasicText)<BasicTextWithTopMarginProps>`
	margin-top: 16px;
	margin-bottom: ${({ bottom }) => (bottom ? '16px' : '0')};
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		text-align: center;
	}
`;

const SecondaryHeading = styled.h3<SecondaryHeadingProps>`
	font-size: 18px;
	font-weight: 500;
	margin-top: ${({ noMargin }) => (noMargin ? '0' : '-16px')};
	margin-bottom: 16px;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		text-align: center;
	}
`;

const CustomButton = styled(Button)`
	width: 154px;
	height: 48px;
	margin: 16px 0;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		width: 244px;
		margin: 16px auto;
	}
`;

interface SuccessProps {
	listing: Listing;
	history: History;
	remainingBookingSlots?: number;
}
//TODO: Remove Success page once redirect to inbox is finalised.
export const Success = ({ listing, history, remainingBookingSlots }: SuccessProps) => {
	const [similarListings, setSimilarListings] = useState([]);

	const handleInboxRedirect = (): void => {
		history.push('/my-bookings');
	};

	useEffect(() => {
		api.searchV2.getSimilarListings(listing.uuid).then((response: any) => {
			setSimilarListings(response.data);
		});
	}, [listing]);

	return (
		<>
			<Title success mobiCentered>
				Success!
			</Title>
			{listing.external ? (
				<>
					<SecondaryHeading>
						Woohoo! Your enquiry has been submitted to our International Team, and we’ll get back to
						you shortly by email!
					</SecondaryHeading>
					<BasicTextWithTopMargin>
						If you haven’t heard from us within 24 hours, you can contact us on
						hello@digsconnect.com.
					</BasicTextWithTopMargin>
				</>
			) : (
				<>
					<SecondaryHeading>Your enquiry has been sent to the landlord.</SecondaryHeading>
					<BasicTextWithTopMargin bottom>
						You can view your introduction with this landlord in your tenant inbox
					</BasicTextWithTopMargin>
					<CustomButton noMargin isPink onClick={handleInboxRedirect}>
						View Inbox
					</CustomButton>
				</>
			)}
			<InfoBlock
				pink
				fullWidth
				isExclamation
			>{`You have ${remainingBookingSlots} out of your 5 booking slots available.`}</InfoBlock>
			<SafetyTips />
			<SimilarListingsAccordion
				titleText="Here are some recommended listings near the one you just enquired to."
				listings={similarListings}
			/>
		</>
	);
};
