import styled from 'styled-components';

interface BasicTextProps {
	noMargin?: boolean;
}

export const BasicText = styled.div<BasicTextProps>`
	line-height: 1.3;
	opacity: 0.8;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '16px')};
`;
