import React from 'react';

import { match } from 'react-router-dom';
import styled from 'styled-components';

import { BREAKPOINT_DESKTOP_PX, BREAKPOINT_DESKTOP_LARGE_PX } from 'constants/breakPoints';
import { ListingBlock } from '../styled-subcomponents/ListingBlock';
import { EnquiryInfoBlock } from '../styled-subcomponents/EnquiryInfoBlock';

const StyledRightPanel = styled.div`
	display: none;
	min-width: 350px;
	overflow-y: auto;

	@media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
		display: inline;
		width: 350px;
	}

	@media (min-width: ${BREAKPOINT_DESKTOP_LARGE_PX}px) {
		width: 450px;
	}
`;

const RightPanelContent = styled.div`
	margin: 32px;
`;

interface RightPanelProps {
	listing: any;
	match: match<{
		listingId: string;
		propertyId: string;
		roomId?: string;
	}>;
	enquiryInfoStep: any;
}

const RightPanel = ({ listing, match, enquiryInfoStep }: RightPanelProps) => (
	<StyledRightPanel>
		<RightPanelContent>
			{listing && <EnquiryInfoBlock enquiryInfoStep={enquiryInfoStep} />}
			{listing && <ListingBlock listing={listing} selectedRoomId={match.params.roomId} />}
		</RightPanelContent>
	</StyledRightPanel>
);

export default RightPanel;
