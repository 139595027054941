import React from 'react';

import Swal from 'sweetalert2';
import styled from 'styled-components';

import api from 'api';
// TODO: Replace below Input with lib Input
import Input from 'components/user-profile/shared-components/Input';
import FormElementWrapper from 'components/user-profile/shared-components/FormElementWrapper';
import theme from 'config/theme';
import { InfoText } from '../../styled-subcomponents/InfoText';

const { colors } = theme;

const StepTwo = ({ number, otp, onOtpChange }) => {
	const resendSubmit = async () => {
		const errorMessage = 'Something went wrong, the OTP could not be sent';
		const successMessage = 'An OTP has been sent to your phone';

		try {
			const response = await api.user.resendVerificationCode();
			if (response.status >= 200 && response.status < 300) {
				fireSubmissionSuccess(successMessage);
			} else {
				fireSubmissionError(response.data.error.message || errorMessage);
			}
		} catch (err) {
			fireSubmissionError(errorMessage);
		}
	};

	const fireSubmissionSuccess = message => {
		Swal.fire({
			title: message,
			type: 'success',
			showConfirmButton: false,
			toast: true,
			timer: 3000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	const fireSubmissionError = message => {
		Swal.fire({
			title: message,
			type: 'warning',
			showConfirmButton: false,
			toast: true,
			timer: 4000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	return (
		<>
			<InfoText>Please enter your One Time Pin below</InfoText>
			<form>
				<FormElementWrapper>
					<Input disabled value={number} />
				</FormElementWrapper>
				<FormElementWrapper>
					<Input
						placeholder="OTP"
						type="number"
						name="otp"
						value={otp}
						onChange={event => {
							onOtpChange(event.target.value);
						}}
					/>
				</FormElementWrapper>
				<InfoText>
					Not receiving an OTP? <ResendLink onClick={resendSubmit}>Resend</ResendLink>
				</InfoText>
			</form>
		</>
	);
};

export default StepTwo;

const ResendLink = styled.span`
	color: ${colors.darkTurquoise};
	text-decoration: underline;
	cursor: pointer;
`;
