import React, { useState } from 'react';

import styled from 'styled-components';
import moment from 'moment';

import { InfoBlock } from '../styled-subcomponents/InfoBlock';
import theme from 'config/theme';
import { validationErrors } from 'config/validationErrors';
import { StepTitle } from '../styled-subcomponents/StepTitle';
import { Divider } from 'lib/Divider';
import api from 'api';
import { SubTitle } from '../styled-subcomponents/SubTitle';
import { Listing } from 'models/listing';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import SafetyTips from 'components/digs-listing/SafetyTips';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

const { colors, fonts, fontSizes, fontWeights, inputs } = theme;

const TextAreaWraper = styled.div`
	position: relative;
`;

const InfoText = styled.div<{ noMargin?: boolean; bold?: boolean; isGrey?: boolean }>`
	font-size: 16px;
	line-height: 1.3;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '16px')};
	opacity: ${({ bold }) => (bold ? '1' : '.8')};
	color: ${({ isGrey }) => (isGrey ? colors.infoGrey : colors.darkBlue)};
`;

const InfoTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
const AdditionalInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;
const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const AdditionalInfoContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-bottom: 16px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding-bottom: 0px;
	}
`;
const TextAreaUpdated = styled.textarea`
	width: 100%;
	min-width: 100%;
	height: 164px;
	outline: transparent;
	border-radius: ${inputs.borderRadius};
	padding: 16px;
	margin-bottom: 16px;
	font-family: ${fonts.Gilroy};
	color: ${colors.darkBlue};
	resize: none;
	background: ${colors.grey05};
	font-size: 16px;

	::placeholder {
		color: ${colors.grey60};
		font-size: 16px;
	}
`;

const TextArea = styled.textarea`
	width: 100%;
	min-width: 100%;
	height: 164px;
	outline: transparent;
	border-radius: ${inputs.borderRadius};
	padding: 16px;
	margin-bottom: 16px;
	font-family: ${fonts.Gilroy};
	color: ${colors.darkBlue};
	resize: none;
	background: ${colors.grey10};

	::placeholder {
		color: ${colors.grey60};
	}
`;

const ValidationError = styled.div<{ visible?: boolean }>`
	font-size: 12px;
	color: ${colors.pink};
	margin-top: -24px;
	padding: 4px 16px;
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
	margin-bottom: 0;
`;
const LandlordContainer = styled.div`
	display: flex;
	align-items: center;
	img {
		border-radius: 50%;
		width: 100px;
		height: 100px;
		margin-right: 16px;
	}
	div {
		display: flex;
		flex-direction: column;
		height: 90px;
		justify-content: space-around;
	}
`;

const LandlordContainerUpdated = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
`;

const LandlordInfoTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 10px;
`;

const LandlordInfoContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
	img {
		border-radius: 50%;
		width: 90px;
		height: 90px;
	}
`;

const InfoPopup = styled.div`
	position: absolute;
	padding: 8px;
	border-radius: 12px;
	background: ${colors.white};
	left: 0;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.darkBlue};
	line-height: 150%;
	z-index: 1;
	min-width: 200px;
	visibility: hidden;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
`;

const AdditionalInfoTitle = styled.div`
	color: ${colors.infoGrey};
	font-size: 16px;
`;

const LandlordTitle = styled.div`
	color: ${colors.darkBlue};
	font-size: 16px;
	font-weight: ${fontWeights.bold};
`;

const AvailableSlotsContainer = styled.div`
	position: relative;
`;

interface AdditionalInfoProps {
	text: string;
	showValidationErrors?: boolean;
	listing: Listing;
	remainingBookingSlots?: number;
	onTextChange(text: string): void;
}

export const AdditionalInfo = ({
	text,
	showValidationErrors,
	listing,
	remainingBookingSlots,
	onTextChange,
}: AdditionalInfoProps) => {
	const placeholder = `Write your message...

Eg. Nice to meet you! I am very interested in moving in on the 1st February, can we arrange a viewing? Is it pet friendly, because I have a cat?`;
	const [placeholderText, setPlaceholderText] = useState<string>(placeholder);
	const [landlordResponseTime, setLandlordResponseTime] = useState<number>(0);
	const [isValidLength, setIsValidLength] = useState<boolean>(true);
	const { landlord } = listing;

	if (!listing.external) {
		api.searchV2.getLandlordStats(landlord.uuid).then(res => {
			if (!res.data) {
				return;
			}
			setLandlordResponseTime(res.data.response_time_hours);
		});
	}

	const handleOnTextChange = (text: string) => {
		onTextChange(text);
		setIsValidLength(text.length >= 10 && text.length <= 500);
	};

	return (
		<>
			{featureFlagContainer.isEnabled(FeatureFlag.InboxRedirect) ? (
				<AdditionalInfoContainer>
					<TitleContainer>
						<StepTitle noMargin>Connect with landlord</StepTitle>
					</TitleContainer>
					<AdditionalInfoContentContainer>
						<InfoContainer>
							<InfoTextContainer>
								<SubTitle>Write your first message to the landlord</SubTitle>
								<TextAreaWraper>
									<TextAreaUpdated
										placeholder={placeholderText}
										value={text}
										onChange={event => handleOnTextChange(event.target.value)}
										onFocus={() => setPlaceholderText('')}
										onBlur={() => setPlaceholderText(placeholder)}
										style={{ minHeight: '120px' }}
									/>
								</TextAreaWraper>
								{showValidationErrors && (
									<ValidationError visible={text.length < 10 || text.length > 500}>
										{validationErrors.InvalidField}
									</ValidationError>
								)}
							</InfoTextContainer>
							{featureFlagContainer.isEnabled(FeatureFlag.InboxRedirect) ? (
								<>
									<SafetyTips hideFindOutMore />
								</>
							) : (
								<InfoBlock pink fullWidth>
									Do you need to know about pets or partners? Are you a smoker or do you perhaps
									require handicap assistance? Now's the time to let them know.
								</InfoBlock>
							)}
						</InfoContainer>
					</AdditionalInfoContentContainer>
				</AdditionalInfoContainer>
			) : (
				<>
					<StepTitle>Connect with landlord</StepTitle>
					<InfoText>All fields are required</InfoText>
					<SubTitle>Meet the landlord</SubTitle>

					<LandlordContainer>
						<img src={landlord.profile_picture.url} alt="The Landlord" />
						<div>
							<InfoText noMargin bold>
								{landlord.first_name}, {landlord.last_name_initial}
							</InfoText>
							<InfoText noMargin>
								Joined DigsConnect in {moment(landlord.join_date, 'YYYY-MM-DD').year()}
							</InfoText>
							{Boolean(landlordResponseTime) && (
								<InfoText noMargin>Typically replies within {landlordResponseTime} hours</InfoText>
							)}
						</div>
					</LandlordContainer>

					<Divider />
					<InfoText>
						This is your first message to the landlord. Introduce yourself and include any
						additional information
					</InfoText>
					<TextArea
						placeholder={`Hey there. I'm a first year studying BCom Economics and looking for accommodation from 1 February. Please can I arrange a viewing on Friday at 2pm?`}
						value={text}
						onChange={event => onTextChange(event.target.value)}
					></TextArea>
					{showValidationErrors && (
						<ValidationError>{validationErrors.InvalidField}</ValidationError>
					)}
					<InfoBlock pink fullWidth>
						Do you need to know about pets or partners? Are you a smoker or do you perhaps require
						handicap assistance? Now's the time to let them know.
					</InfoBlock>
				</>
			)}
		</>
	);
};
