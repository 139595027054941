import theme from 'config/theme';
import styled from 'styled-components';

const { colors } = theme;

export const LoginPromptContainer = styled.div`
	display: flex;
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const LoginLink = styled.span`
	color: ${colors.darkBlue};
	text-decoration: underline;
	margin-left: 8px;
	cursor: pointer;
	font-weight: 700;
`;
