import React from 'react';

import styled from 'styled-components';

import { BREAKPOINT_TABLET_PX, BREAKPOINT_DESKTOP_LARGE_PX } from 'constants/breakPoints';
import theme from 'config/theme';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/checkbox-checked-green.svg';
import { ReactComponent as LockIcon } from 'assets/images/icons/lock.svg';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';
import { Title } from 'lib/Title';
import { Button } from 'lib/Button';
import { SubTitle } from '../styled-subcomponents/SubTitle';

const { colors } = theme;

const StyledLeftPanel = styled.div`
	display: none;
	background: ${colors.gray};
	padding: 32px 0;
	padding-bottom: 0;
	min-width: 305px;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		display: flex;
		flex-direction: column;
		width: 350px;
	}

	@media (min-width: ${BREAKPOINT_DESKTOP_LARGE_PX}px) {
		width: 450px;
	}
`;

const LeftPanelScrollableContent = styled.div`
	width: 100%;
	flex: 1;
	overflow-y: auto;
`;

const LeftPanelContent = styled.div`
	padding: 0 32px;
	width: 100%;
`;

const LeftPanelStepContainer = styled.div<{ isSelected?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	border-radius: 16px;
	background: ${({ isSelected }) => (isSelected ? colors.shadedGray : 'transparent')};
	margin: 0 -16px;
	padding: 0 16px;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
`;

const LeftPanelStepText = styled.div<{ isSelected?: boolean }>`
	font-weight: ${({ isSelected }) => (isSelected ? '600' : 'normal')};
	font-size: 16px;
	opacity: ${({ isSelected }) => (isSelected ? '1' : '0.8')};
`;

interface LeftPanelCheckboxContainerProps {
	noBorder?: boolean;
	noFade?: boolean;
	greyBorder?: boolean;
}

const LeftPanelCheckboxContainer = styled.div<LeftPanelCheckboxContainerProps>`
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	border-radius: 24px;
	align-items: center;
	border: ${({ noBorder, greyBorder }) =>
		noBorder ? 'none' : `2px solid ${greyBorder ? colors.darkBlue : colors.darkTurquoise}`};
	opacity: ${({ noFade }) => (noFade ? '1' : '0.5')};
`;

const ActionBar = styled.div<{ greyBackground?: boolean }>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 8px 16px;
	border-top: 1px solid ${colors.gray};
	background: ${({ greyBackground }) => (greyBackground ? colors.gray : 'transparent')};

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		padding: 16px 24px;
	}
`;

interface LeftPanelProps {
	stepSequence: any[];
	currentStep: any;
	handleStepSelect: any;
	stepStatuses: any;
	firstIncompleteStep: any;
	handleBackToListingClick: any;
	StepStatus: any;
	stepTitles: any;
}

const LeftPanel = ({
	stepSequence,
	currentStep,
	handleStepSelect,
	stepStatuses,
	firstIncompleteStep,
	handleBackToListingClick,
	StepStatus,
	stepTitles,
}: LeftPanelProps) => (
	<StyledLeftPanel>
		<LeftPanelScrollableContent>
			<LeftPanelContent>
				<SubTitle>Let's make that booking enquiry</SubTitle>
				<Title>Still to do:</Title>
				{stepSequence.slice(0, stepSequence.length - 1).map(step => {
					return (
						<LeftPanelStepContainer
							isSelected={currentStep === step}
							key={step}
							onClick={() => handleStepSelect(step)}
						>
							<LeftPanelStepText isSelected={currentStep === step}>
								{stepTitles[step]}
							</LeftPanelStepText>
							<LeftPanelCheckboxContainer
								noBorder={stepStatuses[step] === StepStatus.Complete}
								noFade={stepStatuses[step] === StepStatus.Complete || currentStep === step}
								greyBorder={
									stepStatuses[step] === StepStatus.Incomplete && step !== firstIncompleteStep
								}
							>
								{stepStatuses[step] === StepStatus.Complete && (
									<CheckboxCheckedIcon style={{ height: '100%', width: '100%' }} />
								)}
								{stepStatuses[step] === StepStatus.Incomplete && step === firstIncompleteStep && (
									<PlusIcon
										style={{
											height: '16px',
											width: '16px',
											fill: colors.darkTurquoise,
										}}
									/>
								)}
								{stepStatuses[step] === StepStatus.Incomplete && step !== firstIncompleteStep && (
									<LockIcon
										style={{
											height: '14px',
											width: '14px',
											fill: colors.darkBlue,
										}}
									/>
								)}
							</LeftPanelCheckboxContainer>
						</LeftPanelStepContainer>
					);
				})}
			</LeftPanelContent>
		</LeftPanelScrollableContent>
		<ActionBar>
			<Button isFullWidth isOutline onClick={handleBackToListingClick}>
				Back to listing
			</Button>
		</ActionBar>
	</StyledLeftPanel>
);

export default LeftPanel;
