import styled from 'styled-components';

interface InfoTextProps {
	noMargin?: boolean;
	bold?: boolean;
}

export const InfoText = styled.div<InfoTextProps>`
	font-size: ${({ bold }) => (bold ? '16px' : '14px')};
	line-height: 1.3;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '16px')};
	opacity: ${({ bold }) => (bold ? '1' : '.8')};
`;
