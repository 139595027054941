import React from 'react';

import styled from 'styled-components';

import { InfoText } from './InfoText';
import theme from 'config/theme';
import { ReactComponent as QuestionIconCirc } from 'assets/images/icons/question-mark-circle-outline.svg';
import { ReactComponent as ExclamationIconCirc } from 'assets/images/icons/exclamation-circle-outline.svg';

const { colors } = theme;

interface ContainerProps {
	fullWidth?: boolean;
	noMargin?: boolean;
}

interface IconProps {
	pink?: boolean;
}

const iconSize = '20px';

const InfoPopup = styled.div`
	position: absolute;
	padding: 8px;
	border-radius: 12px;
	background: ${colors.white};
	left: 0;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.darkBlue};
	line-height: 150%;
	z-index: 1;
	min-width: 200px;
	visibility: hidden;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
`;

const InfoPopupContainer = styled(InfoPopup)`
	display: flex;
	flex-wrap: wrap;
	top: -40px;
	min-width: unset;
	width: auto;
`;

const Container = styled.div<ContainerProps>`
	max-width: ${({ fullWidth }) => (fullWidth ? 'none' : '400px')};
	display: flex;
	border: 1px solid ${colors.gray};
	border-radius: 16px;
	padding: 16px;
	padding-left: 8px;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0px' : '16px')};
`;
const LeftBar = styled.div<ContainerProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 12px;
	posiltion: relative;

	&:hover ${InfoPopupContainer} {
		visibility: visible;
	}
`;
const QuestionIcon = styled(QuestionIconCirc)<IconProps>`
	width: ${iconSize};
	height: ${iconSize};
	fill: ${({ pink }) => (pink ? colors.pink : colors.darkBlue)};
`;

const ExclamationIcon = styled(ExclamationIconCirc)<IconProps>`
	width: ${iconSize};
	height: ${iconSize};
	fill: ${({ pink }) => (pink ? colors.pink : colors.darkBlue)};
`;

interface InfoBlockProps {
	children: string;
	fullWidth?: boolean;
	pink?: boolean;
	isExclamation?: boolean;
	onHoverText?: string;
	noMargin?: boolean;
}

export const InfoBlock = ({
	children,
	fullWidth,
	pink,
	isExclamation,
	onHoverText,
	noMargin,
}: InfoBlockProps) => {
	return (
		<Container fullWidth={fullWidth} noMargin={noMargin}>
			<LeftBar>
				<InfoPopupContainer>{onHoverText}</InfoPopupContainer>
				{isExclamation ? <ExclamationIcon pink={pink} /> : <QuestionIcon pink={pink} />}
			</LeftBar>
			<InfoText noMargin>{children}</InfoText>
		</Container>
	);
};
// );
