export enum Step {
	Unknown,
	PersonalInfo,
	FundingInfo,
	MobileVerify,
	AdditionalInfo,
	Success,
}

export const stepTitles: { [step: string]: string } = {
	[Step.PersonalInfo]: 'Tell us about yourself',
	[Step.FundingInfo]: 'Leasing arrangements',
	[Step.MobileVerify]: 'Verification',
	[Step.AdditionalInfo]: 'Connect with landlord',
	[Step.Success]: 'Success',
};

export const fullStepSequence = [
	Step.PersonalInfo,
	Step.FundingInfo,
	Step.MobileVerify,
	Step.AdditionalInfo,
	Step.Success,
];

export enum StepStatus {
	Complete,
	Incomplete,
}
