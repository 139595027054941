import { Step } from './steps';

export interface EnquiryInfo {
	header: string;
	content: { title: string; reason: string }[];
}

export const enquiryInfo: { [step: string]: EnquiryInfo } = {
	[Step.PersonalInfo]: {
		header: 'Why we need this information',
		content: [
			{
				title: 'Personal details',
				reason:
					'Personal information is used in order to save your account information, and to notify the landlord on who is contacting them.',
			},
			{
				title: 'Education',
				reason:
					'We use this information to assist and inform the institutions on the students we place.',
			},
		],
	},
	[Step.FundingInfo]: {
		header: 'Why we need this information',
		content: [
			{
				title: 'Accommodation financing',
				reason:
					'This is captured in order to let the landlord know how rent will be handled, as well as informing DigsConnect on the number of NSFAS and bursary students placed.',
			},
			{
				title: 'Room preference',
				reason: 'This let’s the landlord know which room you are potentially interested in.',
			},
			{
				title: 'Lease period',
				reason:
					'This let’s the landlord know when you would ideally like to occupy the property, and for how long.',
			},
		],
	},
	[Step.MobileVerify]: {
		header: 'Why we need this information',
		content: [
			{
				title: 'OTP Verification',
				reason:
					'We use One Time Pin verification to ensure you are “you”. It also let’s the landlord know how to get in touch with you if needed. This verification process only happens once.',
			},
		],
	},
	[Step.AdditionalInfo]: {
		header: 'Introduction tips',
		content: [
			{
				title: 'What should you include?',
				reason:
					'Introduce yourself, keep it friendly and let the landlord know if you have any questions.',
			},
		],
	},
	[Step.Success]: {
		header: 'One last thing',
		content: [
			{
				title: 'So what happens next?',
				reason:
					"The landlord will be notified and has 48 hours to respond to your enquiry!\n Don't worry - we'll follow up to ensure you get a response.",
			},
			{
				title: 'Want to cancel?',
				reason:
					"It's better to cancel before the landlord accepts because once the landlord has accepted, you'll need to submit a form detailing why you're cancelling the request. You can cancel from your bookings tab.",
			},
		],
	},
};
