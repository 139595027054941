import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { BasicText } from './BasicText';
import { Listing, LeaseType } from 'models/listing';
import numberFormatService from 'services/numberFormatService';
import listingService from 'services/listingService';
import urlUtils from 'utils/urlUtils';

const { colors } = theme;

const Container = styled.div`
	margin-bottom: 16px;
`;

const Image = styled.img`
	border-radius: 16px;
	width: 100%;
	max-width: 450px;
	height: 200px;
	object-fit: cover;
	margin-bottom: 16px;
`;

const Title2 = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const SmallText = styled.div<{ noMargin?: boolean }>`
	font-size: 12px;
	line-height: 1.3;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '16px')};
	opacity: 0.6;
`;

const PinkText = styled(BasicText)`
	color: ${colors.pink};
	font-weight: 600;
`;

const getUniversityDistanceText = (universityDistances: any[]): string => {
	if (!universityDistances || !universityDistances.length) {
		return '';
	}

	const minKmPerUniversity = universityDistances.map(university => {
		const { name, distances } = university;

		const kmArray = distances.map(({ distance }: any) =>
			typeof distance === 'string' ? +distance.split(' ')[0] : distance,
		);
		const minKm = Math.min(...kmArray);

		return { name, km: minKm };
	});

	const closestUniversity = minKmPerUniversity.reduce(
		(acc, val) => (acc.km < val.km ? acc : val),
		minKmPerUniversity[0],
	);
	const { km, name } = closestUniversity;

	return `${km}km away from ${name}`;
};

interface ListingBlockProps {
	listing: Listing;
	selectedRoomId?: string;
}
export const ListingBlock = ({ listing, selectedRoomId }: ListingBlockProps) => {
	const room = !!selectedRoomId && listing.rooms.find(({ uuid }) => uuid === selectedRoomId);
	const image = room ? room.photos[0] : listing.photos[0];
	const imageUrl = (image && image.url) || '';
	const universityDistanceText = getUniversityDistanceText(listing.university_distances);

	return (
		<Container>
			<Image
				src={urlUtils.appendQueryParams(
					imageUrl,
					'auto=format&auto=compress&h=200px&w=450&crop=fit',
				)}
			/>
			<SmallText noMargin={!!universityDistanceText}>
				{listingService.getLocationText(listing.location)}
			</SmallText>
			{!!universityDistanceText && <SmallText>{universityDistanceText}</SmallText>}
			<PinkText>
				{listing.lease_type === LeaseType.EntirePlace
					? `Entire place • ${listing.bedroom_count} bedroom`
					: `Single room`}
			</PinkText>
			<Title2>
				{listing.title}
				{room && room.title ? ` • ${room.title}` : ''}
			</Title2>
			<Title2>
				{numberFormatService.formatCurrency(
					room ? room.price : listing.price,
					listingService.getCurrencySymbol(listing.currency),
				)}
			</Title2>
		</Container>
	);
};
